console.log('_aksandwich.js v4.0.1 ...');

//v4.0.1 added noslidetoggle
//v4.0.0 added akbutton support

function akSandwich(call){ console.log('akSandwich() ->')
  
  if (call == 'close' || call == 'open' || call == 'toggle'){
    if (call == 'close'){
      if( !$('.aksandwich').hasClass('noslidetoggle') ) $('.aksandwich').slideUp(200);
      $('.aksandwich, .aksandwich-button, .aksandwich-indicator, .aksandwich-button button.akbutton').removeClass('open');
    }
    else if (call == 'open'){
      if( !$('.aksandwich').hasClass('noslidetoggle') ) $('.aksandwich').slideDown(200);
      $('.aksandwich, .aksandwich-button, .aksandwich-indicator, .aksandwich-button button.akbutton').addClass('open');
    }
    else if (call == 'toggle'){
      if( !$('.aksandwich').hasClass('noslidetoggle') ) $('.aksandwich').slideToggle(200);
      $('.aksandwich, .aksandwich-button, .aksandwich-indicator, .aksandwich-button button.akbutton').toggleClass('open');
    }
    return;
  }

  $(document).on('click', '.aksandwich-button', function(event){
    if ( $(event.target).hasClass('aksandwich-button-noclick') || $(event.target).closest('.aksandwich-button-noclick').length ) return;
    akSandwich('toggle');
  });

  // $(document).on('swipeup', '.aksandwich', function(){
  //   akSandwich('close')
  // });

  if( $('#aksandwich-automenu').length ){

    var $akautomenu = $('#aksandwich-automenu');

    var akSandwichonscrolldelay = false;
    var akSandwichtmpspt = getspt();;
    window.onscroll = function (e) {
      if (akSandwichonscrolldelay) window.clearTimeout(akSandwichonscrolldelay);
        
      akSandwichonscrolldelay = window.setTimeout(function(){

        if ( getspt() < akSandwichtmpspt ){
          $akautomenu.attr('class', 'up')
        }
        else{
          $akautomenu.attr('class', 'down')
        }

        if ( getspt() < getbh() ){
          $akautomenu.addClass('top')
        }

        akSandwichtmpspt = getspt();
      }, 50);
    }

  }

}

