console.log('_akswiper.js v2.7 ...');

//
//
// //VIEWSWIPER
// $.fn.akViewSwiper = function(){ console.log('akViewSwiper() ->');
//
//   this.each(function(index, el) {
//
//     var $that = $(this);
//
//     $that.addClass('akviewswiper').addClass('akviewswiper'+index);
//
//     $that.find('.swiper-container:first>.swiper-wrapper').find('>.swiper-slide').each(function(index, el) {
//       //$(this).attr('data-hash', $(this).find('.field.title:first').text().replace(/\W+/g, '') );
//       if ( $(this).find('>article.node').attr('about') ){
//         hash = $(this).find('>article.node').attr('about').split('/').pop();
//         $(this).attr('data-hash', hash);
//         console.log('akviewswiper() -> #hash is '+hash)
//       }
//       else console.log('akviewswiper() -> no #hash here')
//     });
//
//     $that.find('>.swiper-container').swiper({
//       direction: 'horizontal',
//       slidesPerView: 1,
//
//       loop: false,
//
//       hashnav: true,
//
//       shortSwipes: true,
//       touchAngle: 20,
//
//       nextButton: $that.find('.swiper-button-next'),
//       prevButton: $that.find('.swiper-button-prev'),
//
//       pagination: $that.find('.view-swiper-pagination'),
//       paginationClickable: true,
//       paginationBulletRender: function (index, className) {
//         return '<span class="' + className + '">' + $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').eq(index).find('.field.title:first').text() +'</span>';
//       }
//     });
//
//     // $that.find('>.swiper-container')[0].swiper.on('onSlideChangeStart', function(swiper){
//     //   swiper.disableMousewheelControl();
//     //   setTimeout(function(){
//     //     swiper.enableMousewheelControl()
//     //   }, 1500);
//     // });
//
//     var thisSwiper = $that.find('>.swiper-container')[0].swiper;
//
//     // $('article#node-9').on('click', function(event) {
//     //   event.preventDefault();
//     //   thisSwiper.slideTo( 1 );
//     // });
//
//     thisSwiper.on('onSlideChangeStart', function(swiper) {
//       akViewSwiper_onSlideChangeStart(swiper);
//     });
//
//     thisSwiper.on('onSlideChangeEnd', function(swiper) {
//       akViewSwiper_onSlideChangeEnd(swiper);
//     });
//
//     // function hidetitle(){
//     //   clearInterval(hidetitleTimeout);
//     //   $('article.node-image-section-like .field.title').hide();
//     //   $('.swiper-slide-active article.node-image-section-like .field.title').fadeIn(500);
//     //
//     //   hidetitleTimeout = setTimeout(function () {
//     //     $('article.node-image-section-like .field.title').fadeOut(2000);
//     //   }, 1000);
//     // }; hidetitle();
//
//   }); //each
// };
//
//


var akImageSwiperCounter = 0;

//IMAGESWIPER
$.fn.akImageSwiper = function(){ console.log('akimageswiper() ->');

  this.each(function(index, el) {

    var $that = $(this);

    if ( $that.hasClass('akimageswiper') ){
      console.log('is already .akimageswiper');
      return;
    }
    akImageSwiperCounter++
    console.log('init .akimageswiper'+akImageSwiperCounter);

    $that.addClass('akimageswiper').addClass('akimageswiper'+akImageSwiperCounter);

    //$that.find('.swiper-slide img').akCalculateImage('stretch',1);

    if ( $that.find('.swiper-slide').length < 2){ console.log('only one img here – nothing to swipe');
      $that.addClass('only-one-slide');
      $that.find('.swiper-slide').addClass('only-swiper-slide');
      $that.closest('.akimageswiper').find('.swiper-button').remove();
      return;
    }

    $that.find('.swiper-container').swiper({
      direction: 'horizontal',
      slidesPerView: '1',
      // centeredSlides: true,
      // freeMode: true,

      shortSwipes: true,
      touchAngle: 20,
      simulateTouch: false,

      grabCursor: false,

      mousewheelControl: false,

      loop: true,

      paginationType: 'fraction',

      //nextButton: $that.find('.swiper-button-next'),
      //prevButton: $that.find('.swiper-button-prev'),
      pagination: $that.find('.imageswiper-pagination'),
      paginationClickable: true,
      // paginationBulletRender: function (index, className) {
      //   return '<span class="' + className + '">' + (index + 1) + '</span>';
      // },

    });

    // $that.find('.swiper-container').on('click', function(e){ 
       
    //   if ( $(e.target).hasClass('prev') ) {
    //     $(this)[0].swiper.slidePrev(true, 250);
    //   }
    //   else{
    //     $(this)[0].swiper.slideNext(true, 250);
    //   }

    //   $(this)[0].swiper.update(true);

    // });

    // $that.find('.swiper-container .prev').on('click', function(e){ 
    //   e.preventDefault();

    //   $(this)[0].swiper.slidePrev(true, 250);
    //   $(this)[0].swiper.update(true);

    //   //console.log('swiper on click -> '+ swiper.clickedIndex);
    //   // if (typeof thisSwiper.clickedIndex !== 'undefined'){
    //   //   thisSwiper.slideTo( thisSwiper.clickedIndex );
    //   // }
    // });

    $that.find('img').load(function(event){
      // var date = new Date();
      // console.log('img loaded '+akImageSwiperCounter +' '+date.getTime());
      $(this).closest('.swiper-container')[0].swiper.update(true);
    });

  }); //each
};
















//
//
//
// //IMAGEFADER
// $.fn.akImageFader = function(time, closesestelement){ console.log('akimagefader() ->');
//   excludeLinks = typeof closesestelement !== 'undefined' ? closesestelement : false;
//
//   this.each(function(index, el) {
//
//     var $that = $(this);
//
//     $that.addClass('akimagefader').addClass('akimagefader'+index);
//
//     $that.find('.fader-slide').find('img').akCalculateImage('fill');
//
//     var $active, $next, $prev;
//
//     $that.find('.fader-slide:first').addClass('akimagefader-active');
//     setClasses();
//
//     function toNext(){
//       if (closesestelement != false) if ( $that.closest(closesestelement).length == 0 ) return;
//
//       console.log('akimagefader() -> toNext');
//
//       setClasses();
//
//       setTimeout(function(){
//         //if ( !$that.find('.akimagefader-prev') ) return false;
//         $next.addClass('akimagefader-active').removeClass('akimagefader-next');
//         $active.addClass('akimagefader-prev').removeClass('akimagefader-active');
//         $prev.removeClass('akimagefader-prev');
//       },1000);
//     }
//
//     function setClasses(){
//
//       $active = $that.find('.fader-slide.akimagefader-active');
//       $next = $active.next('.fader-slide').length ? $active.next('.fader-slide') : $that.find('.fader-slide:first')
//       $next.addClass('akimagefader-next');
//       $prev = $active.prev('.fader-slide').length ? $active.prev('.fader-slide') : $that.find('.fader-slide:last')
//       $prev.addClass('akimagefader-prev');
//
//     }
//
//     // $that.on('click', function(event) {
//     //   event.preventDefault();
//     //   toNext();
//     // });
//
//     var animated = setInterval(function(){
//       toNext()
//     }, time);
//
//   });
// }
// //$('.field-name-field-images-fader').akImageFader(4000, '.swiper-slide-active');
//
//
