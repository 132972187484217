console.log('_jplayer.js 1.5 ...');


$.fn.akjplayerA = function(){

  this.each(function(jplayerindex){ console.log('akjplayerA() ->');

    $(this).addClass('akjplayerA akjplayerA_'+jplayerindex);

    $(this).append('<div class="jp-container"></div>');
    $(this).append('<div class="jp-controls"></div>');

    $(this).find('div.jp-controls').append('<div class="jp-play"></div><div class="jp-pause"></div><div class="jp-secondary"><div class="jp-progress"><div class="jp-seek-bar"><div class="jp-play-bar"></div></div></div><div class="jp-current-time"></div></div>');

    $(this).find('div.jp-container').jPlayer({

      ready: function () {
        $(this).jPlayer("setMedia", {
          mp3: $(this).closest('.akjplayerA').attr('data-audio')
        });
      },
      cssSelectorAncestor: '.akjplayerA_'+jplayerindex+' .jp-controls',
      swfPath: ".",
      supplied: "mp3",
      play: function(e){
        console.log('jPlayer() -> play');
        $(this).jPlayer('pauseOthers');
      },
      timeupdate: function(e){
        console.log('jPlayer() -> timeupdate');
      },
      seeking: function(e){
        console.log('jPlayer() -> seeking');
        $(this).closest('div.akjplayerA').find('.jp-controls').addClass('jp-buffering')
      },
      seeked: function(e){
        console.log('jPlayer() -> seeked');
        $(this).closest('div.akjplayerA').find('.jp-controls').removeClass('jp-buffering')
      }
    });

  });

  // $(document).on('click', 'div.akjplayer div.jp-seek-bar', function(event) { console.log('.jp-seek-bar <- click');
  //   event.preventDefault();
  //   var ct = $(this).closest('div.akjplayer').find('div.jp-container').data("jPlayer").status.currentTime;
  //   var sp = $(this).closest('div.akjplayer').find('div.jp-container').data('jPlayer').status.seekPercent;
  // });

};

//$('div.akjplayerA').akjplayerA();







$.fn.akjplayerV = function(){

  this.each(function(jplayerindex){ console.log('akjplayerV() ->');

    $(this).addClass('akjplayerV akjplayerV_'+jplayerindex);

    $(this).append('<div class="jp-container"></div>');
    $(this).append('<div class="jp-video-play"></div>');
    $(this).append('<div class="jp-controls"></div>');

    //$(this).find('div.jp-video-play').append('<button class="jp-video-play-icon" role="button" tabindex="0">play</button>');

    $(this).find('div.jp-controls').append('<div class="jp-play"></div><div class="jp-pause"></div><div class="jp-secondary"><div class="jp-progress"><div class="jp-seek-bar"><div class="jp-play-bar"></div></div></div><div class="jp-current-time"></div></div>');

    $(this).find('div.jp-container').jPlayer({

      ready: function () {
        $(this).jPlayer("setMedia", {
          m4v: $(this).closest('.akjplayerV').attr('data-video')
        });
      },
      cssSelectorAncestor: '.akjplayerV_'+jplayerindex+' .jp-controls',
      swfPath: ".",
      supplied: "m4v",
      play: function(e){
        console.log('jPlayer() -> play');
        $(this).jPlayer('pauseOthers');
      },
      timeupdate: function(e){
        console.log('jPlayer() -> timeupdate');
      },
      seeking: function(e){
        console.log('jPlayer() -> seeking');
        $(this).closest('div.akjplayerV').find('.jp-controls').addClass('jp-buffering')
      },
      seeked: function(e){
        console.log('jPlayer() -> seeked');
        $(this).closest('div.akjplayerV').find('.jp-controls').removeClass('jp-buffering')
      }
    });

  });

  // $(document).on('click', 'div.akjplayer div.jp-seek-bar', function(event) { console.log('.jp-seek-bar <- click');
  //   event.preventDefault();
  //   var ct = $(this).closest('div.akjplayer').find('div.jp-container').data("jPlayer").status.currentTime;
  //   var sp = $(this).closest('div.akjplayer').find('div.jp-container').data('jPlayer').status.seekPercent;
  // });

};

//$('div.akjplayerV').akjplayerV();
