console.log('init.js v3.0.0 ...');

//v3.0.0 added akajaxToggleCallbackSS

//GLOBALS
var AKscroll;
var spt = getspt();

//INIT
(function init(){ console.log('init() ->');

  //INITFUNCTIONS
    //Start with a boilerplate
    boilerplate();

    //Show notice for outdated browsers
    akoutdated();

    //Set body classes
    $('body').akbildschirm(true);

    //Adminmenu Functions
    adminmenu(); //Set keyboard shortcuts
    hidemessages();
    akadminbutton();

    //Process Forms
    $('.webform-container-inline').akwrapformitems('.form-item'); //?


    //document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);


  //Ajax

  $('.views-field-title a').akajax({
    closestSelector: '.row',
    findSelector: false,
    resultSelector: '#ajaxresult>.node>.element',
    transitions: 'full',
    ajaxcontainer: 'append',
    toggle: true,
    callbackIfExist: false,
    reloadIfExist: false,
    callback: function(){  console.log('.views-field-title a -> callback()');

      $('.field-name-field-bilder').akImageSwiper();
      $('.field-name-field-workbilder').akImageSwiper();
      works();

      //$('.akImageSwiper .swiper-container')[0].swiper.slideTo(0, 0, true);
      //   $('html, body').animate({
      //    scrollTop: $(".ajax").offset().top
      //  }, 250);
      // $('.akimageswiper:not(.only-one-slide)').find('.swiper-container')[0].swiper.update(true);

    }
  });
  





  //$('.view-events a, .view-events-kids a').akarm('article.node');


  //BASIC
    //Init Sandwich-Button
    akSandwich();

    //Init jPlayers
    // $('.akjplayerA').akjplayerA();
    // $('.akjplayerV').akjplayerV();

    //Init Swipers
    // $('.view-swiper').akViewSwiper();
    // $('.field-name-field-images-swiper').akImageSwiper();
    $('.field-name-field-bilder').akImageSwiper();
    $('.field-name-field-workbilder').akImageSwiper();
    works();

    //$('#AKonepagenav').akonepagenav('.view-pageslide', 'article.node', '.field.title');

  //EXTRA
    // $('#node-10 .jp-controls').remove();
    // $('#node-10 .jp-container').bind($.jPlayer.event.ended, function(event) {
    //   $(this).jPlayer("play");
    // });


    // $('.swiper-slide-active .akjplayerV .jp-container').bind($.jPlayer.event.ready, function(event) { $(this).jPlayer("play"); });

    // AKscroll = new IScroll('#wrapper', {}); akscrollbeat(); console.dir(AKscroll.options);




  //EVENTS
  buttons();
  triggers();
  formatters();
  externallinks();
  //Remove Ajax
  removeajaxresult();

  //Start Beatmakers
  //startbeatmaker(10);
  //startscrollbeat();


})();

//CALLBACKS

  //Swipers
  function akViewSwiper_onSlideChangeStart(swiper){
    //$('.akjplayerV .jp-container').jPlayer('pause');
  }

  function akViewSwiper_onSlideChangeEnd(swiper){
    //$('.swiper-slide-active .akjplayerV .jp-container').jPlayer('play');
  }

  function akajaxToggleCallback($el, opening){ console.log('akajaxToggleCallback')
    if ( opening ){
      $el.find('.akimageswiper>.swiper-container').each(function(){ console.log('slideTo 0')
        $(this)[0].swiper.slideTo(1, 0, true);
      });
    }
  }

//BEATMAKER

  function beatmaker(){
    //EVENTS
    console.log('tick');
  }


  function scrollbeat(){
    //EVENTS
    //spt = getspt(); //check for global
  }


  // function akscrollbeat(){
  //   AKscroll.on('scrollEnd', function () {
  //     console.log('SCROLL: '+this.y)
  //   });
  // }


//under the lid

  function startscrollbeat(){
    $(window).scroll(function(){
      scrollbeat();
    });
  }
  function startbeatmaker(fps){
    window.setTimeout(beatmaker, 1000 / fps);
    beatmaker();
  }
