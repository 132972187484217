console.log('_events.js ...');

function buttons(){ console.log('buttons() ->')

  $('.aksandwich-open-button .akbutton').on('click', function(){
    $(this).toggleClass('open')
    $('nav.sandwich-open').toggleClass('open')
  })

  $('.field-name-field-groupofpersonstitle').on('click', function(){
    $(this).find('.akbutton').toggleClass('open');
    $(this).siblings('.field-collection-container').slideToggle();
  })

  $('.field-name-field-personsname').each(function(){
    if ( $(this).siblings().length == 0 ){
      $(this).find('.akbutton').remove();
      $(this).css('cursor','auto')
    } 
  })

  $('.field-name-field-personsname').on('click', function(){
    $(this).find('.akbutton').toggleClass('open');
    $(this).siblings('.field').slideToggle();
  })

  $('.field.title').on('click', function(){
    $(this).find('.akbutton').toggleClass('open');
    $(this).siblings('.field').slideToggle();
  })


  $('footer button.aktop').hide().on('click', function(){
    $("html, body").animate({ scrollTop: "0" });
  })
  
  $(window).scroll(function(){

    $('footer button.aktop').show();
  });



  // $('.akimageswiper .img').hover(function(event) {
  //     $(this).find('.aknext').css({top: event.clientY, left: event.clientX}).show();
  // }, function() {
  //     $(this).find('.aknext').hide();
  // });

  $(document).on('mouseenter mousemove', '.akimageswiper .swiper-container', function(e) {
    
    if(e.clientX-$(this).offset().left > 100){
      $(this).parent().find('.prev').hide();
      $(this).parent().find('.next').show().css({top: e.clientY-$(this).offset().top+$(document).scrollTop(), left: e.clientX-$(this).offset().left});
    }
    else{
      $(this).parent().find('.next').hide();
      $(this).parent().find('.prev').show().css({top: e.clientY-$(this).offset().top+$(document).scrollTop(), left: e.clientX-$(this).offset().left});
    }
    
  });

  $(document).on('mouseleave', '.akimageswiper .swiper-container', function(e) {
    $(this).parent().find('.next, .prev').hide();
  });

  var blocked = false;

  $(document).on('click', '.akimageswiper .swiper-container', function(e) { console.log('blocked '+ blocked)
    // if(blocked) return false;
    
    if(e.clientX-$(this).offset().left > 100){ console.log('next') 
      $(this)[0].swiper.slideNext(false, 250);
      $(this)[0].swiper.update(true);
    }
    else{ console.log('prev')
      $(this)[0].swiper.slidePrev(false, 250);
      $(this)[0].swiper.update(true);
    }

    // blocked = true;
    // setTimeout( function(){blocked=false}, 500);


  });




}

function triggers(){ console.log('triggers() ->')

  var sem = $('.views-field-field-semester:first').text()

  $('.views-field-field-semester').each(function(index) {

    if( $(this).text() == sem ){
      $(this).closest('.row').find('.views-field-title a').trigger('click');
    }

  });

}

function formatters(){ console.log('formatters() ->')

  $('.views-field-field-semester').each(function(index) {
    var semester = $(this).text()+': '
    $(this).parent().find('>.views-field-title a').prepend(semester);
  });

  //$('#node-44').find('.field-name-field-text').hide();

}

function externallinks(){ console.log('externallinks() ->')

  $('a[href^="http:"]').attr('target','_blank');

}



function works(){ console.log('works() ->')

  $('.field-name-field-work .field-collection-view').each(function(){
    $(this).find('.field-name-field-workbilder').hide();

    if ( $(this).find('.field-name-field-workbilder').length <= 0 ) $(this).find('.akplusx').remove();
    
    $(this).find('.field-name-field-titel').off();
    $(this).find('.field-name-field-titel').on('click', function(){

      //prevent flicker
      $(this).parent().find('.field-name-field-workbilder').toggle();
      $(this).parent().find('.swiper-container')[0].swiper.update(true);
      $(this).parent().find('.field-name-field-workbilder').toggle();

      $(this).parent().find('.akbutton:first').toggleClass('open');

      $(this).parent().find('.field-name-field-workbilder').slideToggle(function(){
        $(this).find('.swiper-container')[0].swiper.update(true);	
        
      });
    })
})

}

